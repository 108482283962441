import React, { useState } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
// ---------------------------------------------
import Layout from "../components/layout"
import RichText from "../components/richtext"
import Revealer from "../components/revealer"
import { SwatchesV2 } from "../components/swatches"
import SEO from "../components/seo"
// ---------------------------------------------

const MaterialItem = ({ material }) => {
  const { variants, id, type, description } = material
  const [selection, setSelection] = useState(variants?.[0])

  const handleVideoTouch = e => {
    e.target.play()
  }

  return (
    <div className="materials-item">
      <div className="upcase materials-item-name hidden-desktop">
        <h3>{selection?.name || type}</h3>
      </div>
      <div className="materials-item-detail">
        <div className="upcase materials-item-name hidden-mobile">
          <h3>{selection?.name || type}</h3>
        </div>

        {variants?.length > 1 && (
          <>
            <div className="materials-item-variants p2">
              <p>
                Available in {variants?.length} colour
                {variants?.length > 1 && "s"}
              </p>
            </div>
            <div className="materials-item-swatch swatch-options">
              <ul className="swatch-colours innerx2 no-bullets">
                {variants?.map(node => {
                  // console.log(node)
                  return (
                    <li
                      className={`swatch-option small ${
                        node.name === selection.name ? "active" : ""
                      }`}
                      onClick={() => setSelection(node)}
                      key={node.name}
                      style={{
                        backgroundColor: node.colour || "",
                      }}
                    />
                  )
                })}
              </ul>
            </div>
          </>
        )}
        {description && (
          <div className="materials-item-description rte hidden-mobile">
            <RichText content={description} />
          </div>
        )}
      </div>
      <div className="materials-media">
        <div className="materials-item-thumbnail swatch-thumbnail">
          {selection?.image && <Img fluid={selection.image.thumbnail} />}
        </div>
        <div className="materials-item-gallery">
          <div className="gallery-inner">
            {selection?.gallery?.map(media => {
              let isLandscape = media.fluid?.aspectRatio >= 1 || false

              return (
                <div
                  key={media.id}
                  className={`gallery-item ${isLandscape ? "landscape" : ""}`}
                  onClick={() =>
                    !isLandscape &&
                    setSelection({
                      image: {
                        thumbnail: media.fluid,
                      },
                    })
                  }
                >
                  {media.fluid && <Img fluid={media.fluid} />}
                  {media.file.contentType == "video/mp4" && media.file.url && (
                    <div className="pabs fs preview">
                      <div className="prel fs ohide">
                        <div
                          className="pabs"
                          style={{
                            width: "105%",
                            height: "105%",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <video
                            src={media.file.url}
                            className="pabs fit-cover"
                            autoPlay
                            muted
                            loop
                            playsInline
                            onTouchStart={handleVideoTouch}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {description && (
        <div className="materials-item-description rte hidden-desktop">
          <RichText content={description} />
        </div>
      )}
    </div>
  )
}

const MaterialsPage = ({ data }) => {
  const { title, description, materials } = data.contentfulMaterialsPage

  const materialsPages = data.allContentfulMaterialsPage?.nodes
  const variantsSorted =
    materialsPages?.sort((a, b) => b.materials?.length - a.materials?.length) ||
    []

  const activePathname =
    typeof window != "undefined" ? window.location.pathname : "/"

  return (
    <Layout footerColour="#FFFFFF">
      <SEO title={title} />
      <Revealer>
        <section>
          <div className="materials-header">
            <h1 className="upcase nm">Materials</h1>
            <h1 className="mbx4">{title}</h1>
            <div className="p3 align-left">
              <RichText content={description} debug={title} />
            </div>
            {/* <p>
              <Link to="/journal"><em>{`<< back to The Journal`}</em></Link>
            </p> */}
          </div>
          <div className="materials-navigation">
            <nav>
              {variantsSorted?.map(({ id, title, slug }) => (
                <Link
                  key={`nav-${id}`}
                  to={`/materials/${slug}`}
                  className={`${
                    activePathname === `/materials/${slug}` ? "active" : ""
                  }`}
                >
                  {title}
                </Link>
              ))}
            </nav>
          </div>
          <div className="materials-list">
            {materials?.map((material, i) => (
              <MaterialItem
                key={`material-${material.id}-${i}`}
                material={material}
              />
            ))}
          </div>
        </section>
      </Revealer>
    </Layout>
  )
}

MaterialsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MaterialsPage

export const materialsPageQuery = graphql`
  query MaterialsPageQuery($slug: String!) {
    contentfulMaterialsPage(slug: { eq: $slug }) {
      title
      slug
      description {
        raw
      }
      materials {
        id
        type
        description {
          raw
        }
        # gallery {
        #   id
        #   fluid(maxWidth: 800) {
        #     ...GatsbyContentfulFluid_withWebp_noBase64
        #   }
        #   file {
        #     contentType
        #     url
        #   }
        # }
        variants {
          ...Swatch
        }
      }
    }
    allContentfulMaterialsPage {
      nodes {
        id
        title
        slug
        materials {
          id
        }
      }
    }
  }
`
